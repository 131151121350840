





import {Vue, Component, Prop} from "vue-property-decorator";
import SearchFriendListTitType from "./indexType";

@Component({name: "SearchFriendListTit"})
export default class SearchFriendListTit extends Vue implements SearchFriendListTitType{

    @Prop(String)
    value!:string
    get getValue(){
        return this.value || '推荐你想认识的人'
    }
}
