










import {Vue, Component, Ref, Watch, Emit} from "vue-property-decorator";
import HeadTitType from "./indexType";
import FindFriendSearch from "@/views/Friend/AddressBook/components/Search/index.vue";
import {back} from "@/util/zoom";

@Component({name: "HeadTit",components:{ FindFriendSearch }})
export default class HeadTit extends Vue implements HeadTitType{
    @Ref("searchInput")
    searchInput:any

    goIcon = require("@/assets/icon/Basics/GoBack.png")
    focusShow = false
    value = ""

    mounted(){
        let time = setTimeout(()=>{
            this.searchInput.focus()
            clearTimeout(time)
        })
    }

    handleFocus(){
        this.focusShow = true
    }

    handleBlur(){
        this.focusShow = false
    }

    handleClickBack(){
        if ( this.value.length ){
            this.value = ""
        }else{
            this.$router.go(-1);
            try{
                back()
            }catch (e) {
                console.info("返回失败")
            }
        }
    }

    @Emit("keyupEnter")
    keyupEnter(){
        return true
    }

    @Emit("pullAndChangeValue")
    @Watch("value")
    pullAndChangeValue(data:string){
        return data
    }
}
