























import {Vue, Component, Watch} from "vue-property-decorator";
import SearchFriendType from "./SearchFriend";
import ZoomPage from "../../../impView/PageSafety";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import HeadTit from "@/views/Friend/SearchFriend/components/HeadTit/index.vue";
import SearchFriendItem from "@/views/Friend/components/FriendItem/index.vue";
import SearchFriendListTit from "@/views/Friend/SearchFriend/components/ListTit/index.vue";
import {getRecommendList} from "@/views/Friend/FindFriend/Server";
import {searchFriends} from "@/views/Friend/SearchFriend/Server";
import {FindFriendStore} from "@/views/Friend/FindFriend/Store";
import {SearchFriendStore} from "@/views/Friend/SearchFriend/Store";

@Component({name: "SearchFriend",components:{ HeadTit,PullDownUpList,ListBottom,SearchFriendItem,SearchFriendListTit }})
export default class SearchFriend extends ZoomPage implements SearchFriendType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []
    RecommendFriend = true

    mounted(){
        this.loadingShow = false
    }

    activated(){
        this.List = this.getRecommendList
        if ( this.List.length < 1 ) {
            let upData = FindFriendStore.getFindFriendUpData;
            upData.pageNo = 1;
            FindFriendStore.SetFindFriendUpData(upData)
            this.handleRecommend(true)
        }
    }

    handleGetValue(value:string){
        if (value.length) {
            this.RecommendFriend = false
            let upData = SearchFriendStore.getSearchFriendUpData;
            upData.phones = value
            SearchFriendStore.SetSearchFriendUpData(upData)
        }else{
            this.RecommendFriend = true
            if ( this.getRecommendList.length ) this.List = this.getRecommendList;
            else {
                let upData = FindFriendStore.getFindFriendUpData;
                upData.pageNo = 1;
                FindFriendStore.SetFindFriendUpData(upData)
                this.List = []
                this.handleRecommend(true)
            }
        }
    }

    handleKeyDown(){
        let upData = SearchFriendStore.getSearchFriendUpData;
        upData.pageNo = 1
        SearchFriendStore.SetSearchFriendUpData(upData)
        this.List = []
        this.handleUpData(true)
    }

    handlePullDown(){
        this.PullDown = true
        this.StopUp = false
        if ( this.RecommendFriend ){
            let upData = FindFriendStore.getFindFriendUpData;
            upData.pageNo = 1;
            FindFriendStore.SetFindFriendUpData(upData)
            this.handleRecommend(true)
        }else{
            let upData = SearchFriendStore.getSearchFriendUpData;
            upData.pageNo = 1
            SearchFriendStore.SetSearchFriendUpData(upData)
            this.handleUpData(true)
        }
    }

    handleUpDown(){
        if( this.StopUp )return;
        this.UpDownBool = true
        if ( this.RecommendFriend ){
            let upData = FindFriendStore.getFindFriendUpData;
            upData.pageNo += 1
            FindFriendStore.SetFindFriendUpData(upData)
            this.handleRecommend()
        }else{
            let upData = SearchFriendStore.getSearchFriendUpData;
            upData.pageNo += 1
            SearchFriendStore.SetSearchFriendUpData(upData)
            this.handleUpData()
        }
    }

    handleRecommend(bool=false){
        // 推荐好友
        getRecommendList().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < FindFriendStore.getFindFriendUpData.pageSize )this.StopUp = true;
            if ( bool )
                FindFriendStore.SetFindFriendList( res );
            else
                FindFriendStore.SetFindFriendList( FindFriendStore.getFindFriendList.concat(res) );
        })
    }

    handleUpData(bool=false){
        // 搜索好友
        searchFriends().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < SearchFriendStore.getSearchFriendUpData.pageSize )this.StopUp = true;
            if ( bool )
                SearchFriendStore.SetSearchFriendList( res );
            else
                SearchFriendStore.SetSearchFriendList( SearchFriendStore.getSearchFriendList.concat(res) );
            if ( res.length == 1 ){
                this.$router.push({
                    name:"addFriend",
                    query:{
                        id:res[0]["friendId"] || '2'
                    }
                })
            }
        })
    }

    get getRecommendList(){
        return FindFriendStore.getFindFriendList
    }
    @Watch("getRecommendList")
    changeRecommendList(newVal:any[]){
        if (this.RecommendFriend)this.List = newVal;
    }

    get getSearchList(){
        return SearchFriendStore.getSearchFriendList
    }
    @Watch("getSearchList")
    changeSearchList(newVal:any[]){
        if (!this.RecommendFriend)this.List = newVal;
    }
}
